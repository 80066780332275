import React from 'react'
import { Oval } from 'react-loader-spinner'
import './Loader.css'

export default function Loader() {
    return (
        <div className='loader'>
            <Oval height={80} width={80} color="#282c34" wrapperStyle={{}} wrapperClass="" visible={true} ariaLabel='oval-loading' secondaryColor="#4fa94d" strokeWidth={2} strokeWidthSecondary={2} />
        </div>
    )
}
