/* eslint-disable react-hooks/exhaustive-deps */
import { Form } from 'react-bootstrap';
import { Col, Button } from 'react-bootstrap'
import "./PurchasePanel.css"
import { useEffect, useState } from 'react';
import axios from '../../config/axios';
import Toaster from '../../helper/Toaster';

function PurchaseLeftPanel({ setLoading, setcouponcode }) {

  const [couponCode, setcouponCode] = useState("")
  const [couponError, setcouponError] = useState(null)
  const [planDetails, setplanDetails] = useState({})

  const [planPrice, setplanPrice] = useState("")

  const [couponDetails, setcouponDetails] = useState(null)

  const ApplyCoupon = async (event) => {
    event.preventDefault()
    if (!couponCode) return setcouponError("Enter valid coupon code")
    await axios.post(`/apply-coupon`, { planid: sessionStorage.getItem("planId"), couponcode: couponCode }).then(({ data }) => {
      if (data.success) {
        setcouponDetails(data.coupon)
        setcouponcode(couponCode)
        if (data.coupon.discount_type === "Percentage") {
          setplanPrice((pre) => pre - ((pre * data.coupon.discount_percentage) / 100))
        } else if (data.coupon.discount_type === "Fixed Amount") {
          setplanPrice((pre) => pre - data.coupon.max_discount)
        }
        Toaster(data.message, "success")
      } else {
        setcouponCode("")
        Toaster(data.message, "error")
      }
    }).catch((error) => {
      setcouponCode("")
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  const GetSinglePlan = async () => {
    setLoading(true)
    await axios.get(`/plan/${sessionStorage.getItem("planId")}`).then(({ data }) => {
      setLoading(false)
      if (data.success) {
        setplanDetails(data.data)
        setplanPrice(data.data.price)
      } else {
        Toaster(data.message, "error")
      }
    }).catch((error) => {
      setLoading(false)
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  useEffect(() => {
    GetSinglePlan()
  }, [])

  return (
    <Col md="4" className="PurchaseLeftPanel p-5">
      <h3 className="h3_heading mb-3">Membership Plans</h3>
      <h4 className='h4_heading mb-4'>You selected - {planDetails.name} </h4>
      <ul className="plan_point_list mt-3 mb-5">
        {
          planDetails?.features?.map((items) => (
            <li>
              <span className='point_head'>{items.description.split("||")[0]}</span>
              <span className='point_desc'>{items.description.split("||")[1]}</span>
            </li>
          ))
        }
      </ul>

      <div className="d-flex justify-content-between mb-3">
        <div className="left_small_heading">Duration</div>
        <strong className="right_small_heading">Until Cancelled</strong>
      </div>
      <div className="d-flex justify-content-between mb-3">
        <div className="left_small_heading">Price</div>
        <strong className="right_small_heading">{planDetails.currencySymbol}{planDetails.price} + VAT per {planDetails.price_type}</strong>
      </div>

      <Form className='apply_coupon' onSubmit={(e) => ApplyCoupon(e)}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Control type="test" value={couponCode} placeholder="Enter coupon code" onChange={(e) => { setcouponCode(e.target.value); setcouponError(null); setcouponDetails(null); setcouponcode(""); setplanPrice(planDetails.price) }} />
          {couponError && (<p className='text-danger position-absolute text-center'> {couponError} </p>)}
        </Form.Group>
        {
          (couponDetails !== null) ? (
            <Button variant="primary" onClick={() => { setcouponCode(""); setcouponError(null); setcouponDetails(null); setcouponcode(""); setplanPrice(planDetails.price) }}>
              Change Coupon
            </Button>
          ) : (
            <Button variant="primary" type="submit">
              Apply
            </Button>
          )
        }
      </Form>


      <div className="d-flex justify-content-between mb-3 mt-4">
        <strong className="right_small_heading d-flex flex-column justify-content-center align-items-start text-center">
          <span> Plan price : - {planDetails.currencySymbol}{planDetails.price} + VAT per {planDetails.price_type} </span>
          <span> Coupon Discount : - {couponDetails !== null ? (couponDetails.discount_type === "Percentage") ? `${couponDetails.discount_percentage}%` : (couponDetails.discount_type === "Fixed Amount") && couponDetails.max_discount + planDetails.currencySymbol : "N/A"} </span>
          <span>Total : -{planDetails.currencySymbol} {planPrice} + VAT </span>
        </strong>
      </div>

      <p className='mt-5 mb-5 text-left'>You will be charged {planDetails.price_type} until cancelled. you can cancel anytime</p>

    </Col>
  )
}

export default PurchaseLeftPanel