/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Tab, Nav } from 'react-bootstrap'
import PurchaseLeftPanel from './PurchaseLeftPanel'

import SignUp from '../signInUp/signUp'
import Checkout from '../../components/payment/Checkout'
import axios from '../../config/axios'
import Toaster from '../../helper/Toaster'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/shared/loader/Loader'


function PurchasePanel() {

  const [activeKey, setactiveKey] = useState("first")
  const [userDetails, setuserDetails] = useState({})
  const [Loading, setLoading] = useState(false)

  const [couponcode, setCouponCode] = useState("")

  const navigate = useNavigate()

  const CompleteRegistrcation = async () => {
    setLoading(true)
    await axios.post('/signup', { ...userDetails, plan: sessionStorage.getItem("planId"), couponcode }).then(({ data }) => {
      setLoading(false)
      if (data.success) {
        navigate(`/thank-you/${userDetails.name}`)
      } else {
        Toaster(data.message, "error")
      }
    }).catch((error) => {
      setLoading(false)
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  useEffect(() => {
    if (!sessionStorage.getItem("planId")) {
      navigate("/membership")
    }
  }, [])

  return (
    <Container fluid>
      <div className='purchase'>
        {Loading && <Loader />}
        <Row>
          <PurchaseLeftPanel setLoading={setLoading} setcouponcode={setCouponCode}></PurchaseLeftPanel>

          <Col md="8" className='mt-5 mb-5'>
            <Tab.Container id="left-tabs-example" activeKey={activeKey} fill>
              <Nav className="mb-3 nav-tabs nav-fill payment_process" as="ul">
                <Nav.Item as="li" className="d-flex justify-content-center">
                  <Nav.Link eventKey="first" className='d-flex flex-column justify-content-center align-items-center'>
                    <span className='step_number'>1</span>
                    <span className='step_small_title mt-2'>STEP 1</span>
                    <h3 className='h3_heading'>Sign Up</h3>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="d-flex justify-content-center">
                  <Nav.Link eventKey="second" className='d-flex flex-column justify-content-center align-items-center'>
                    <span className='step_number'>2</span>
                    <span className='step_small_title mt-2'>STEP 2</span>
                    <h3 className='h3_heading'>Payment</h3>
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="first" className='p-5'>
                  <SignUp setactiveKey={setactiveKey} setuserDetails={setuserDetails} setLoading={setLoading} />
                </Tab.Pane>
                <Tab.Pane eventKey="second" className='p-5'>
                  <Checkout setdetails={userDetails} formsubmit={CompleteRegistrcation} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default PurchasePanel