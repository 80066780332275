import React from 'react';
import { Form } from 'react-bootstrap';
import { SearchIcon, Noti, PrfImg, } from "../../../assets/images";
import './dashboard-header.css';

const DashboardHeader = () => {
    return (
        <div className="dash_header_partts">
            <div className='dash_inner_header'>
                <Form className='dash_form'>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control type="text" placeholder="Search Here" className='search_field' />
                        <span className='search_iconn'><img src={SearchIcon} alt='search_iconn' /></span>
                    </Form.Group>
                </Form>
                <div className='user_prf'>
                    <div className='notification'>
                        <img src={Noti} alt='' />
                        <span className='noti_count'></span>
                    </div>
                    <div className='prf_image d-flex align-items-center'>
                        <div className='prf__img'>
                            <img src={PrfImg} alt='' />
                            <span className='noti_count'></span>
                        </div>
                        <div className='prf_text'>
                            <label>John Doe</label>
                            <span>User</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardHeader;