import React from 'react'
import './Checkout.css'
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { DefaultState } from '../../context/defaultContext';
import { loadStripe } from '@stripe/stripe-js';

export function CardElements({ setdetails, formsubmit }) {
    const stripe = useStripe();
    const elements = useElements();


    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        await stripe.createToken(elements.getElement(CardElement)).then((result) => {
            if (result) {
                setdetails.carddetails = result
                formsubmit()
            }
        }).catch((error) => {
            console.log(error)
        })
    };

    return (
        <form onSubmit={handleSubmit}>
            <CardElement options={CARD_ELEMENT_OPTIONS} />
            <button disabled={!stripe} className="bgl_button mt-3">Confirm Payment</button>
        </form>
    )
}

export default function Checkout({ setdetails, formsubmit }) {

    const { stripeDetails } = DefaultState()
    const stripePromise = loadStripe(stripeDetails);

    return (
        <Elements stripe={stripePromise}>
            <CardElements setdetails={setdetails} formsubmit={formsubmit} />
        </Elements>
    )
}
