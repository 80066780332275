/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import './signinup.css';
import { Link } from 'react-router-dom';
import * as Yup from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import axios from '../../config/axios';
import Toaster from '../../helper/Toaster';
import { DefaultState } from '../../context/defaultContext';
import { City, Country, State } from 'country-state-city';

export default function SignUp({ setactiveKey, setuserDetails, setLoading }) {

  const { deviceDetails } = DefaultState()

  const country = Country.getAllCountries()

  const [mobileVerified, setmobileVerified] = useState(false)
  const [otpGenerated, setotpGenerated] = useState(false)
  const [code, setcode] = useState(null)
  const [allState, setallState] = useState([])
  const [allCity, setallCity] = useState([])

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Please enter your name"),
    email: Yup.string().required("Please enter your email id").email("Please provide a valid email").test({
      message: "This email is already used",
      test: async (value) => {
        let check = await axios.post('/check-email', { email: value })
        return check.data.valid
      }
    }),
    password: Yup.string().required("Please enter your password").min(6, "Password length should be at least 6 characters").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/, "One Uppercase, One Lowercase, One Number and One Special Case Character"),
    confirmPassword: Yup.string().required("Please enter your password again").min(6, "Password length should be at least 6 characters").oneOf([Yup.ref("password")], "Passwords do not match").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/, "One Uppercase, One Lowercase, One Number and One Special Case Character"),
    address: Yup.string().required("Please enter your address"),
    mobile: Yup.string().required("Please enter your mobile number"),
    mobileCode: Yup.string().required("Please enter your mobile code"),
    city: Yup.string().required("Please select your city"),
    state: Yup.string().required("Please select your state"),
    country: Yup.string().required("Please select your country")
  });

  const { register, handleSubmit, formState: { errors }, setError, setValue, clearErrors, getValues, resetField, reset } = useForm({ resolver: yupResolver(formSchema), mode: "all" })

  const CheckMobile = async (mobile, mobileCode) => {
    await axios.post('/check-email', { mobile, mobileCode }).then(({ data }) => {
      if (!data.valid && data.success) {
        setError("mobile", { message: "This mobile already exsist", type: "validate" })
      } else if (data.valid && data.success) {
        clearErrors("mobile")
        clearErrors("mobileCode")
        setValue("mobile", mobile)
        setValue("mobileCode", mobileCode)
      }
    }).catch((error) => {
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  const GenerateOtp = async () => {
    await axios.post('/generate-otp', { mobile: getValues("mobile"), mobileCode: getValues("mobileCode") }).then(({ data }) => {
      if (data.success) {
        Toaster(data.message, "success")
        Toaster(data.code, "info")
        document.querySelector('.form-control.teste').removeAttribute('onchange')
        setotpGenerated(true)
      } else {
        Toaster(data.message, "error")
        if (data.errors) {
          data.errors.map((fields) => {
            return setError(fields.param, { message: fields.msg, type: 'validate' })
          })
        }
      }
    }).catch((error) => {
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  const VerifyMobile = async () => {
    await axios.post('/verify-otp', { mobile: getValues("mobile"), mobileCode: getValues("mobileCode"), code }).then(({ data }) => {
      if (data.success) {
        Toaster(data.message, "success")
        setmobileVerified(true)
      } else {
        Toaster(data.message, "error")
        if (data.errors) {
          data.errors.map((fields) => {
            return setError(fields.param, { message: fields.msg, type: 'validate' })
          })
        }
      }
    }).catch((error) => {
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  const ChangeNumber = async () => {
    await axios.post('/change-number', { mobile: getValues("mobile"), mobileCode: getValues("mobileCode") }).then(({ data }) => {
      if (data.success) {
        setotpGenerated(false)
        setmobileVerified(false)
      } else {
        Toaster(data.message, "error")
        if (data.errors) {
          data.errors.map((fields) => {
            return setError(fields.param, { message: fields.msg, type: "required" }, { shouldFocus: true })
          })
        }
      }
    }).catch((error) => {
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  useEffect(() => {
    setValue("country", deviceDetails?.country_code)
    setallState(State.getStatesOfCountry(deviceDetails?.country_code))
    setallCity(City.getCitiesOfState(deviceDetails?.country_code, deviceDetails?.region_code))
  }, [deviceDetails])

  useEffect(() => {
    if (allState.length > 0 && (allState.findIndex((element) => element.isoCode === deviceDetails?.region_code) >= 0)) {
      setValue("state", deviceDetails?.region_code)
    }
    if (allCity.length > 0 && (allCity.findIndex((element) => element.name === deviceDetails?.city) >= 0)) {
      setValue("city", deviceDetails?.city)
    }
  }, [allState, allCity])

  const onCountryChange = (countryCode) => {
    setallState(State.getStatesOfCountry(countryCode))
    resetField("state")
    resetField("city")
    setallCity([])
  }

  const onStateChange = (state) => {
    setallCity(City.getCitiesOfState(getValues("country"), state))
  }


  const FromSubmit = async (data) => {
    if (!mobileVerified || !otpGenerated) return Toaster("Verify your number first", "error")
    if (!window.confirm("Check your details again !")) return
    setLoading(true)
    setactiveKey("second")
    setuserDetails(data)
    reset()
    setLoading(false)
  }

  return (
    <Form className='loginForm' onSubmit={handleSubmit(FromSubmit)}>
      <Row className="mb-3">
        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">Name <span>*</span></Form.Label>
          <Form.Control placeholder="enter your name" title="enter your name" {...register("name")} />
          {errors.name && (<p className='text-danger position-absolute text-center'> {errors.name.message} </p>)}
        </Form.Group>

        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">Email <span>*</span></Form.Label>
          <Form.Control placeholder="enter your email" title="enter your email" type='email' {...register("email")} />
          {errors.email && (<p className='text-danger position-absolute text-center'> {errors.email.message} </p>)}
        </Form.Group>

        <Form.Group className="col-md-12 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">Address <span>*</span></Form.Label>
          <Form.Control style={{ height: '100px' }} as={"textarea"} placeholder="enter your address" title="enter your address" {...register("address")} />
          {errors.address && (<p className='text-danger position-absolute text-center'> {errors.address.message} </p>)}
        </Form.Group>

        <Col md={12} className='mb-4'>
          <Row className='align-items-baseline justify-content-center text-center'>
            <Form.Group className="col-md-6 phone" controlId="exampleForm.ControlInput1">
              <Form.Label htmlFor="inputPassword5">Phone Number <span>*</span></Form.Label>
              <PhoneInput disabled={otpGenerated} country={deviceDetails?.country_code?.toLowerCase()} searchStyle={{ height: '33px', width: '85%' }} enableSearch={true} label="teste" inputClass="teste" specialLabel="" inputProps={{ required: true }} onChange={(phone, countryCode) => CheckMobile(phone.replace(countryCode?.dialCode, ""), `+${countryCode.dialCode}`)} />
              {errors.mobile && (<p className='text-danger position-absolute text-center'> {errors.mobile.message} </p>)}
            </Form.Group>
            <Form.Group className="col-md-6 phone" controlId="exampleForm.ControlInput1">
              {
                mobileVerified && otpGenerated && (
                  <div>
                    <Button className="bgl_button mt-0" disabled> Verified </Button>
                  </div>
                )
              }
              {
                !mobileVerified && otpGenerated && (
                  <div className='d-flex align-items-baseline justify-content-between text-center'>
                    <div>
                      <Form.Label htmlFor="inputPassword5">Mobile Otp <span>*</span></Form.Label>
                      <Form.Control placeholder="enter your otp" title="enter your otp" onChange={(e) => setcode(e.target.value)} />
                    </div>
                    <div className='w-50 d-flex justify-content-between gap-3 mx-2'>
                      <Button className="bgl_button mt-0 w-50" onClick={() => VerifyMobile()}> Verify </Button>
                      <Button className="bgl_button mt-0 w-50" onClick={() => ChangeNumber()}> Change Number </Button>
                    </div>
                  </div>
                )
              }
              {
                !mobileVerified && !otpGenerated && (
                  <Button className="bgl_button mt-0" onClick={() => GenerateOtp()}> Request otp </Button>
                )
              }
            </Form.Group>

          </Row>
        </Col>

        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">Country <span>*</span></Form.Label>
          <Form.Select aria-label="Default select example" placeholder='selet your country' {...register("country", { onChange: (e) => onCountryChange(e.target.value) })}>
            <option value={""}> Choose country ... </option>
            {
              country.map((item, index) => (
                <option value={item?.isoCode} key={index}>{item?.name}</option>
              ))
            }
          </Form.Select>
          {errors.country && (<p className='text-danger position-absolute text-center'> {errors.country.message} </p>)}
        </Form.Group>

        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">State <span>*</span></Form.Label>
          <Form.Select aria-label="Default select example" placeholder='selet your state' {...register("state", { onChange: (e) => onStateChange(e.target.value) })}>
            <option value={""}> Choose state ... </option>
            {
              allState.map((item, index) => (
                <option value={item?.isoCode} key={index}>{item?.name}</option>
              ))
            }
          </Form.Select>
          {errors.state && (<p className='text-danger position-absolute text-center'> {errors.state.message} </p>)}
        </Form.Group>

        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">City <span>*</span></Form.Label>
          <Form.Select aria-label="Default select example" placeholder='selet your city' {...register("city")}>
            <option value={""}> Choose city ... </option>
            {
              allCity.map((item, index) => (
                <option value={item?.name} key={index}>{item?.name}</option>
              ))
            }
          </Form.Select>
          {errors.city && (<p className='text-danger position-absolute text-center'> {errors.city.message} </p>)}
        </Form.Group>

        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">Referred id (optional)</Form.Label>
          <Form.Control placeholder="enter your referred id" title="enter your referred id" {...register("referredid")} />
          {errors.referredid && (<p className='text-danger position-absolute text-center'> {errors.referredid.message} </p>)}
        </Form.Group>

        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5">Password <span>*</span></Form.Label>
          <Form.Control placeholder="enter your password" title="enter your password " {...register("password")} type='password' />
          {errors.password && (<p className='text-danger position-absolute text-center'> {errors.password.message} </p>)}
        </Form.Group>

        <Form.Group className="col-md-6 mb-4" controlId="exampleForm.ControlInput1">
          <Form.Label htmlFor="inputPassword5"> Confirm Password <span>*</span></Form.Label>
          <Form.Control placeholder="enter your password again" title="enter your password again" {...register("confirmPassword")} type='password' />
          {errors.confirmPassword && (<p className='text-danger position-absolute text-center'> {errors.confirmPassword.message} </p>)}
        </Form.Group>

        <Row className='justify-content-between mt-3'>
          <Col md={4} className='d-flex justify-content-end forgot_pass'>
            <Button className="bgl_button mt-0" type='submit'>Continue</Button>
          </Col>

          <Col md={6} className='d-flex justify-content-end align-items-center forgot_pass'>
            <strong>Already a Member? <Link to='/signin'>Sign in</Link></strong>
          </Col>
        </Row>


      </Row>
    </Form>
  )
}