import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Toaster from "../helper/Toaster";

const defaultContext = createContext()

export default function DefaultProvider({ children }) {
    const [isAutorized, setisAutorized] = useState(false)
    const [user, setuser] = useState({})
    const [stripeDetails, setstripeDetails] = useState(null)
    const [deviceDetails, setdeviceDetails] = useState({ country: 'United States', country_code: 'US', region: '', region_code: '', city: '', zip: '', lat: null, lon: null, timezone: '', isp: '', org: '', as: '', query: '' })

    const GetDeviceDetails = async () => {
        await axios.get(`https://ipapi.co/json/`).then(({ data }) => {
            setdeviceDetails(data)
        }).catch((error) => {
            console.log(error.toString())
        })
    }

    const GetStripeDetails = async () => {
        await axios.get(`https://blgui-devapi.wgtechnologiespvtltd.com/get-all-payment-creds`).then(({ data }) => {
            if (data.success) {
                setstripeDetails(data.details.prublickey)
            }
        })
    }

    useEffect(() => {
        GetDeviceDetails()
        GetStripeDetails()
    }, [])

    const { userdetails, logoutdetails, logouterror } = useSelector((state) => state.login)

    useEffect(() => {
        if (userdetails && userdetails.message) {
            Toaster(userdetails.message, 'success')
        }
        if (userdetails && userdetails.token) {
            setisAutorized(true)
            setuser(userdetails.data)
        }
    }, [userdetails])

    useEffect(() => {
        if (logoutdetails && logoutdetails.message) {
            Toaster(logoutdetails.message, 'success')
        }
        if (logoutdetails || logouterror) {
            setisAutorized(false)
        }
    }, [logoutdetails, logouterror])


    return (
        <defaultContext.Provider value={{ isAutorized, deviceDetails, stripeDetails, user }}> {children} </defaultContext.Provider>
    )
}

export const DefaultState = () => {
    return useContext(defaultContext)
}