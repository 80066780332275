import { Routes, Route } from 'react-router-dom';
import './App.css';

import HomePage from './pages/home/home';
import MembershipPlan from './pages/membershipPlans/Membership.plan';
import PurchasePanel from './pages/MembershipPlanPurchase/PurchasePanel';
import { DefaultState } from './context/defaultContext';
import { Autorized, NotAutorized } from './protection';
import AuthLayout from './layout/authlayout/AuthLayout';
import ActiveAccount from './pages/activeAccount/ActiveAccount';
import ThankYou from './pages/signInUp/ThankYou';
import SignIn from './pages/signInUp/signIn.jsx';
import MainLayout from './layout/mainlayout/MainLayout.jsx';
import Dashboard from './pages/dashboard/dashboard.jsx';

function App() {

  const { isAutorized } = DefaultState()

  return (
    <div className="App">
      <Routes>

        <Route element={<AuthLayout />}>
          <Route path='/' element={<HomePage />} />

          <Route element={<Autorized isAutorized={isAutorized} />}>
            <Route path="/membership" element={<MembershipPlan />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/Purchasepanel" element={<PurchasePanel />} />
          </Route>
        </Route>

        <Route element={<Autorized isAutorized={isAutorized} />}>
          <Route path='/activate-account/:token' element={<ActiveAccount />} />
          <Route path='/thank-you/:user' element={<ThankYou />} />
        </Route>

        <Route element={<MainLayout />}>
          <Route element={<NotAutorized isAutorized={isAutorized} />}>
            <Route path='/dashboard' element={<Dashboard />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;














