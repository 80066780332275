import axios from "../../config/axios"
import { USER_LOGIN_FAILED, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT_FAILED, USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS } from "../constants/authConstant"

export const LoginAction = (data) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST })
        axios.post(`/signin`, data).then(({ data }) => {
            if (data.success) {
                dispatch({ type: USER_LOGIN_SUCCESS, payload: data })
                localStorage.setItem("userdetails", JSON.stringify(data.data))
                localStorage.setItem("authtoken", data.token)
            } else {
                dispatch({ type: USER_LOGIN_FAILED, payload: data.message })
            }
        }).catch((error) => {
            dispatch({ type: USER_LOGIN_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: USER_LOGIN_FAILED, payload: error.response ? error.response.data.message : error.toString() })
    }
}

export const Logout = () => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGOUT_REQUEST })
        axios.get(`/logout`).then(({ data }) => {
            if (data.success) {
                dispatch({ type: USER_LOGOUT_SUCCESS, payload: data })
                localStorage.clear()
            } else {
                localStorage.clear()
                dispatch({ type: USER_LOGOUT_FAILED, payload: data.message })
            }
        }).catch((error) => {
            localStorage.clear()
            dispatch({ type: USER_LOGIN_FAILED, payload: error.response ? error.response.data.message : error.toString() })
        })
    } catch (error) {
        dispatch({ type: USER_LOGIN_FAILED, payload: error.response ? error.response.data.message : error.toString() })
    }
}