import DashLogo from "./logo-dashboard.png";
import Events from "./events.png";
import SearchIcon from "./search_icon.png";
import Noti from "./noti.png";
import PrfImg from "./prf_img.png";
export {
    DashLogo,
    Events,
    SearchIcon,
    Noti,
    PrfImg,
}
