import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { LoginReducer } from "./reducers/authReducer";

const token = localStorage.getItem("authtoken") ? localStorage.getItem("authtoken") : null
const data = localStorage.getItem("userdetails") ? JSON.parse(localStorage.getItem("userdetails")) : null

const reducer = combineReducers({
    login: LoginReducer
})

const initialState = {
    login: { userdetails: { token, data } }
}

const middleware = [thunk]

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)))

export default store