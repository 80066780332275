/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Swal from 'sweetalert2'
import axios from '../../config/axios'
import { useNavigate, useParams } from 'react-router-dom'

export default function ActiveAccount() {

    const { token } = useParams()

    const navigate = useNavigate()

    const SwalFunction = (Loading, type = '', message = 'Your account will be activating') => {
        if (Loading) {
            return Swal.fire({
                title: 'Please wait',
                text: message,
                padding: '3em',
                color: '#716add',
                allowEscapeKey: false,
                allowEnterKey: false,
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        } else {
            return Swal.fire({
                icon: type,
                html: message,
                padding: '3em',
                color: '#716add',
                allowEscapeKey: false,
                allowEnterKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    setTimeout(() => {
                        Swal.close()
                        navigate('/')
                    }, 2000);                    
                }
            })
        }
    }

    const ActivateAccount = async () => {
        SwalFunction(true)
        await axios.post(`/active-account`, { token }).then(({ data }) => {
            if (data.success) {
                SwalFunction(false, 'success', data.message)
            } else {
                SwalFunction(false, 'error', data.message)
            }
        }).catch((error) => {
            SwalFunction(false, 'error', error.response ? error.response.data.message : error.toString())
        })
    }

    useEffect(() => {
        ActivateAccount()
    }, [])

    return (
        <div className='min-vh-100 d-flex align-items-center justify-content-center text-center'></div>
    )
}
