/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./Membership.plan.css"

import { Container, Row, Col } from 'react-bootstrap'

import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";

import Plan from "./plan";
import axios from "../../config/axios";
import Toaster from "../../helper/Toaster";
import Loader from "../../components/shared/loader/Loader";

function MembershipPlan() {

  const [activeTab, setActiveTab] = useState(0);
  const [plancategory, setplancategory] = useState([])
  const [PlanList, setPlanList] = useState([])
  const [Loading, setLoading] = useState(false)

  const GetAllCategory = async () => {
    setLoading(true)
    await axios.get('/plan-category').then(({ data }) => {
      setLoading(false)
      if (data.success) {
        setplancategory(data.data)
        if (data.data.length > 0) {
          GetPlanList(data.data[0]._id)
        }
      }
    }).catch((error) => {
      setLoading(false)
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  const GetPlanList = async (category_id) => {
    setLoading(true)
    await axios.get(`/plans/${category_id}`).then(({ data }) => {
      setLoading(false)
      if (data.success) {
        setPlanList(data.data)
      }
    }).catch((error) => {
      setLoading(false)
      Toaster(error.response ? error.response.data.message : error.toString(), "error")
    })
  }

  useEffect(() => {
    GetAllCategory()
  }, [])

  const onTabClick = (e, index) => {
    setActiveTab(index)
  };

  useEffect(() => {
    if (document.getElementsByClassName("rts___tab___selected").length > 0) {
      GetPlanList(document.getElementsByClassName("rts___tab___selected")[0].getAttribute("name"))
    }
  }, [activeTab])

  return (
    <Container>
      {Loading && <Loader />}
      <div className='membership-plan mt-5 mb-5 '>
        <h3 className="h3_heading pb-3">Membership Plans</h3>
        <Col md={{ span: 8, offset: 2 }} className="pb-5" ><p>Buy a plan of your choice to be the part of the community portal and get access to all the resources at your fingertip for a eco-friendly living.</p></Col>
        <Tabs activeTab={activeTab} onTabClick={onTabClick}
          isRTL={false}
          // didReachEnd={(val) => console.log(val)}
          // didReachStart={(val) => console.log(val)}
          tabsScrollAmount={1}
          animationDuration={300}
          navBtnCLickAnimationDuration={300}
          selectedAnimationDuration={300}
          hideNavBtns={false}
          hideNavBtnsOnMobile={true}
          showTabsScroll={false}
          // getTabsBoundingClientRects={(val) => console.log(val)}
          //   action={ref}
          mode="scrollSelectedToCenterFromView"
          //    navBtnAs="div"
          tabsContainerClassName=""
          tabsUpperContainerClassName=""
          //   tabsContainerClassName=""
          leftNavBtnClassName=""
          rightNavBtnClassName=""
          navBtnClassName=""
          navBtnContainerClassName=""
          navBtnStyle={{}}
          tabsContainerStyle={{}}
          tabsUpperContainerStyle={{}}>

          {plancategory.map((item) => (
            <Tab key={item._id} id={item._id} name={item._id}>{item.categoryName}</Tab>
          ))}
        </Tabs>

        {
          plancategory.map((item, index) => (
            <TabScreen key={index} id={item._id} name={item._id} activeTab={activeTab} index={index} className="some-animation-class">
              <Row>
                {
                  PlanList.map((element) => (
                    <Plan key={element._id} details={element} />
                  ))
                }
              </Row>
            </TabScreen>
          ))
        }

      </div>
    </Container>
  )
}

export default MembershipPlan