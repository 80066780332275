import React from 'react'
import { Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function plan({ details }) {
    return (
        <Col md={4} className='mt-5'>
            <div className='plan_box p-3'>
                <strong>{details.name}</strong>
                <div className='amt'>{details.currencySymbol}{details.price} <span>+ VAT per month</span></div>
                <hr></hr>
                <ul className="plan_point_list">
                    {
                        details.features.map((items) => (
                            <li>
                                <span className='point_head'>{items.description.split("||")[0]}</span>
                                <span className='point_desc'>{items.description.split("||")[1]}</span>
                            </li>
                        ))
                    }
                </ul>
                <Link to='/Purchasepanel' onClick={() => sessionStorage.setItem("planId", details._id)} className='bgl_button text-center'>Register Now</Link>
            </div>
        </Col>
    )
}

export default plan