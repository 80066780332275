import React from 'react';
//import "./styles.css";
import { NavLink } from "react-router-dom";
import "./dashboard-left-panel.css";

import { DashLogo, } from "../../../assets/images";

const DashboardLeftPanel = () => {
  return (
    <div className="dash_left_panel">
      <div className='dash_logo'>
        <img src={DashLogo} alt='logo' />
      </div>
      <ul>
        <li>
          <NavLink to="/"><span className='menu_icon'><i className="fa fa-dashboard"></i></span><span className='menu_span'>Dashboard</span></NavLink>
        </li>
        <li>
          <NavLink to="/profile"><span className='menu_icon'><i className="fa fa-user"></i></span><span className='menu_span'>Profile</span></NavLink>
        </li>
        <li>
          <NavLink to="/membership"><span className='menu_icon'><i className="fa fa-credit-card"></i></span><span className='menu_span'>Membership</span></NavLink>
        </li>
        <li>
          <NavLink to="/events"><span className='menu_icon'><i className="fa fa-calendar"></i></span><span className='menu_span'>Events</span></NavLink>
        </li>
        <li>
          <NavLink to="/analytics"><span className='menu_icon'><i className="fa fa-area-chart"></i></span><span className='menu_span'>Analytics</span></NavLink>
        </li>
        <li>
          <NavLink to="/settings"><span className='menu_icon'><i className="fa fa-cog"></i></span><span className='menu_span'>Setting</span></NavLink>
        </li>
        <li>
          <NavLink to="/settings"><span className='menu_icon'><i className="fa fa-question-circle"></i></span><span className='menu_span'> Help</span></NavLink>
        </li>
      </ul>
    </div>
  );
};

export default DashboardLeftPanel;