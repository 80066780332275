// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new_card form label {
    width: 100%;
}

.StripeElement {
    height: 40px !important;
    padding: 10px 12px !important;
    width: 100% !important;
    color: #32325d !important;
    background-color: white !important;
    border: 1px solid transparent !important;
    border-radius: 4px !important;

    box-shadow: 0 1px 3px 0 #e6ebf1 !important;
    transition: box-shadow 150ms ease !important;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df !important;
}

.StripeElement--invalid {
    border-color: #fa755a !important;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/payment/Checkout.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,6BAA6B;IAC7B,sBAAsB;IACtB,yBAAyB;IACzB,kCAAkC;IAClC,wCAAwC;IACxC,6BAA6B;;IAE7B,0CAA0C;IAE1C,4CAA4C;AAChD;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".new_card form label {\n    width: 100%;\n}\n\n.StripeElement {\n    height: 40px !important;\n    padding: 10px 12px !important;\n    width: 100% !important;\n    color: #32325d !important;\n    background-color: white !important;\n    border: 1px solid transparent !important;\n    border-radius: 4px !important;\n\n    box-shadow: 0 1px 3px 0 #e6ebf1 !important;\n    -webkit-transition: box-shadow 150ms ease !important;\n    transition: box-shadow 150ms ease !important;\n}\n\n.StripeElement--focus {\n    box-shadow: 0 1px 3px 0 #cfd7df !important;\n}\n\n.StripeElement--invalid {\n    border-color: #fa755a !important;\n}\n\n.StripeElement--webkit-autofill {\n    background-color: #fefde5 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
