/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function ThankYou() {

    const { user } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        let timerInterval
        Swal.fire({
            icon: 'success',
            title: `Welcome ${user}`,
            html: "Thanks for joining Business Live Global. <br/> Redirect in <b><b/>",
            padding: '3em',
            color: '#716add',
            allowEscapeKey: false,
            allowEnterKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 6000,
            didOpen: () => {
                const b = Swal.getHtmlContainer().querySelector('b')
                timerInterval = setInterval(() => {
                    b.textContent = `${Math.ceil(Swal.getTimerLeft() / 1000)}'s`
                }, 1000)
            },
            willClose: () => {
                clearInterval(timerInterval)
                navigate('/')
            }
        })
    }, [])

    return (
        <div className='min-vh-100 d-flex align-items-center justify-content-center text-center'></div>
    )
}
