import footerlogo from './footer_logo.png';
import rightArrow from './up_arrow.png';
import services from './services.png';
import signupUser from './signup_user.png';
import toggleBar from './bar.png';
import logo from './logo.png'

export {
    footerlogo,
    rightArrow,
    services,
    signupUser,
    toggleBar,
    logo
}