import { USER_LOGIN_FAILED, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS, USER_LOGOUT_FAILED, USER_LOGOUT_REQUEST, USER_LOGOUT_SUCCESS } from "../constants/authConstant";

export const LoginReducer = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true };

        case USER_LOGIN_SUCCESS:
            return { loading: false, userdetails: action.payload }

        case USER_LOGIN_FAILED:
            return { loading: false, error: action.payload }

        case USER_LOGOUT_REQUEST:
            return { ...state, loading: true };

        case USER_LOGOUT_SUCCESS:
            return { loading: false, logoutdetails: action.payload }

        case USER_LOGOUT_FAILED:
            return { ...state, loading: false, logouterror: action.payload }

        default:
            return state;
    }
}