import React from 'react'
import { NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGauge, faRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { DefaultState } from '../../../../context/defaultContext'
import { Logout } from '../../../../redux/actions/authAction'
import { useNavigate } from 'react-router-dom'

export default function UserDropDown() {

    const { user } = DefaultState()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <div>
            <NavDropdown title={user.name} className='profiledropdown'>
                <NavDropdown.Item href="#action/3.1" className='d-flex align-items-center gap-2 mb-2' onClick={() => navigate("/dashboard")}>
                    <FontAwesomeIcon icon={faGauge} /> Dashboard
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2" className='d-flex align-items-center gap-2 mb-2'>
                    <FontAwesomeIcon icon={faUser} /> Profile
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#" className='d-flex align-items-center gap-2' onClick={() => dispatch(Logout())}>
                    <FontAwesomeIcon icon={faRightFromBracket} color='red' /> Logout
                </NavDropdown.Item>
            </NavDropdown>
        </div>
    )
}
