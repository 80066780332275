// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * header css File
*/

.header{
    /* position: absolute;
    top: 30px;
    width: 100%;
    z-index: 9; */
    border-bottom: 2px solid rgba(0,0,0,0.1);
    padding: 10px 0;
}

.inner_header{
    padding:0 50px;
}
.header_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/header/auth-header/header.css"],"names":[],"mappings":"AAAA;;CAEC;;AAED;IACI;;;iBAGa;IACb,wCAAwC;IACxC,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB","sourcesContent":["/*\n * header css File\n*/\n\n.header{\n    /* position: absolute;\n    top: 30px;\n    width: 100%;\n    z-index: 9; */\n    border-bottom: 2px solid rgba(0,0,0,0.1);\n    padding: 10px 0;\n}\n\n.inner_header{\n    padding:0 50px;\n}\n.header_top{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
