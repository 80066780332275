import React from "react";
import { Link } from "react-router-dom";
import "./dashboard.css";
import { Events, } from "../../assets/images";
import { Chart as ChartJS, LinearScale, CategoryScale, PointElement, BarElement, LineElement, ArcElement, Title, Tooltip, Legend, } from 'chart.js';
import { Line, Bar, Pie } from 'react-chartjs-2';
import { Col, Row } from "react-bootstrap";
// var faker = require('faker');

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, ArcElement, Title, Tooltip, Legend);

const options = {
    responsive: true,
    plugins: {
        legend: {
            //position: 'top' as const,
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    scales: {
        y: {
            beginAtZero: true,
        },
    },
    //   plugins: {
    //     legend: {
    //        // Set to false to hide the legend
    //     },
    //   },
};
const options2 = {
    responsive: true,
    plugins: {
        legend: {
            //position: 'top' as const,
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Bar Chart',
        },
    },
};
const labels = ['0', '1', '2', '3', '4', '5', '6', '7'];
const labels2 = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const data = {
    labels,
    datasets: [
        // {
        //     //label: 'Dataset 1',
        //     data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        //     borderColor: 'rgb(255, 99, 132)',
        //     backgroundColor: 'rgba(255, 99, 132, 0.5)',
        // },
        //   {
        //     label: 'Dataset 2',
        //     data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        //     borderColor: 'rgb(53, 162, 235)',
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //   },
    ],
};


const data2 = {
    labels,
    datasets: [
        // {
        //     label: 'Dataset 1',
        //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        //     backgroundColor: '#f84b63',
        // },
        //   {
        //     label: 'Dataset 2',
        //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
        //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
        //   },
    ],
};

export const data3 = {
    labels: ['Tender Writing', 'Funding Solution', 'Business Legal'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3],
            backgroundColor: [
                '#ff8436',
                '#f4396e',
                '#15e9f4',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 0,
        },
    ],
};


export default function Dashboard() {
    return (
        <div className="dashRight">
            <div className="dash_inner_right">
                <div className="dash_right_heading">
                    <h2>Dashboard</h2>
                    <label>Package: <span>Silver</span></label>
                </div>
                <div className="chart_partts">
                    <Row>
                        <Col lg="6" md="6">
                            <div className="growth_status">
                                <h3>Growth Status</h3>
                                <Line options={options} data={data} />
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="growth_status">
                                <h3>Account Activity</h3>
                                <Bar options={options2} data={data2} />
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="activity_relations">
                    <Row>
                        <Col lg="4" md="6" className="activity_partts">
                            <div className="last_activity">
                                <h2>Last Activity</h2>
                                <label>2,965</label>
                            </div>
                        </Col>
                        <Col lg="4" md="6" className="activity_partts">
                            <div className="last_activity">
                                <h2>Public relations</h2>
                                <label>424</label>
                            </div>
                        </Col>
                        <Col lg="4" md="6" className="activity_partts">
                            <div className="last_activity">
                                <h2>Number of post</h2>
                                <label>200</label>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="strategy_sales">
                    <Row>
                        <Col lg="6" md="6">
                            <div className="strategy_box">
                                <h3>Sales Strategy</h3>
                                <Row>
                                    <Col lg="6" md="6">
                                        <div className="label_parts">
                                            <label><span className="one"></span> Tender Writing</label>
                                            <label><span className="two"></span> Funding Solution</label>
                                            <label><span className="three"></span> Business Legal</label>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6">
                                        <div className="pie_chartt">
                                            <Pie data={data3} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="strategy_box">
                                <h3>Video and Audio Brodcasting</h3>
                                <div className="video_inner_box">
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qr4c2bahvic?si=Kl7trR-67Sz2PDt_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                        <div className="video_text">
                                            5 Top Tips for Building your Brand | Business Live TV | EPISODE 3
                                        </div>
                                    </div>
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qr4c2bahvic?si=Kl7trR-67Sz2PDt_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                        <div className="video_text">
                                            5 Top Tips for Building your Brand | Business Live TV | EPISODE 3
                                        </div>
                                    </div>
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/qr4c2bahvic?si=Kl7trR-67Sz2PDt_" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                        <div className="video_text">
                                            5 Top Tips for Building your Brand | Business Live TV | EPISODE 3
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <div className="buy_events">
                    <Row>
                        <Col lg="6" md="6">
                            <div className="strategy_box">
                                <h3>Buy Events</h3>
                                <div className="video_inner_box event_boxx">
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <img src={Events} alt="" />
                                        </div>
                                        <div className="video_text">
                                            The SUPER Network by Business Live Global and Partners ONLINE
                                        </div>
                                        <div className="explore_but"><Link to="/">Explore</Link></div>
                                    </div>
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <img src={Events} alt="" />
                                        </div>
                                        <div className="video_text">
                                            The SUPER Network by Business Live Global and Partners ONLINE
                                        </div>
                                        <div className="explore_but"><Link to="/">Explore</Link></div>
                                    </div>
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <img src={Events} alt="" />
                                        </div>
                                        <div className="video_text">
                                            The SUPER Network by Business Live Global and Partners ONLINE The SUPER Network by Business Live Global and Partners ONLINE
                                        </div>
                                        <div className="explore_but"><Link to="/">Explore</Link></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" md="6">
                            <div className="strategy_box">
                                <h3>Upcoming Events</h3>
                                <div className="video_inner_box event_boxx">
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <img src={Events} alt="" />
                                        </div>
                                        <div className="video_text">
                                            The SUPER Network by Business Live Global and Partners ONLINE
                                        </div>
                                        <div className="explore_but"><Link to="/">Explore</Link></div>
                                    </div>
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <img src={Events} alt="" />
                                        </div>
                                        <div className="video_text">
                                            The SUPER Network by Business Live Global and Partners ONLINE
                                        </div>
                                        <div className="explore_but"><Link to="/">Explore</Link></div>
                                    </div>
                                    <div className="video_inner">
                                        <div className="video_box">
                                            <img src={Events} alt="" />
                                        </div>
                                        <div className="video_text">
                                            The SUPER Network by Business Live Global and Partners ONLINE The SUPER Network by Business Live Global and Partners ONLINE
                                        </div>
                                        <div className="explore_but"><Link to="/">Explore</Link></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
};