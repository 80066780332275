import React from 'react'
import DashboardLeftPanel from '../../components/sidebar/dashboard-left-panel/dashboard-left-panel'
import { Outlet } from 'react-router-dom'
import DashboardHeader from '../../components/header/dashboard-header/dashboard-header'

export default function MainLayout() {
    return (
        <>
            <DashboardHeader />
            <div className='content'>
                <section className="dash_partts">
                    <div className="dash_inner_partts">
                        <div className="dashLeft">
                            <DashboardLeftPanel />
                        </div>
                        <Outlet />
                    </div>
                </section>
            </div>
        </>
    )
}