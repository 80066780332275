import React from 'react'

import './footer.css';
import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';

import { footerlogo } from '../../images'

export default function Footer() {
    return (
        <footer className="footer">
            <div className="footer_top">
                <Container>
                    <Row>
                        <Col lg={5}>
                            <div className="footer_left_partts">
                                <div className="footer_logo">
                                    <img src={footerlogo} alt='logo' />
                                </div>
                                <div className="footer_social_iconss">
                                    <ul>
                                        <li><Link to="#"><FontAwesomeIcon icon={faFacebookF} /></Link></li>
                                        <li><Link to="#"><FontAwesomeIcon icon={faInstagram} /></Link></li>
                                        <li><Link to="#"><FontAwesomeIcon icon={faXTwitter} /></Link></li>
                                        <li><Link to="#"><FontAwesomeIcon icon={faLinkedinIn} /></Link></li>
                                        <li><Link to="#"><FontAwesomeIcon icon={faYoutube} /></Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <div className="menu_footer_sec">
                                <h2>Menu</h2>
                                <ul>
                                    <li><Link to="#">Home</Link></li>
                                    <li><Link to="#">About Us</Link></li>
                                    <li><Link to="#">Membership</Link></li>
                                    <li><Link to="#">Events</Link></li>
                                    <li><Link to="#">Business Growth Services</Link></li>
                                    <li><Link to="#">Business Live Global Media</Link></li>
                                    <li><Link to="#">Testimonials</Link></li>
                                    <li><Link to="#">Contact Us</Link></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="footer_bottom">
                <Container>
                    <div className="footer_inner_bottom">
                        <p>&copy; Copyright @2023. All Rights Reserved.</p>
                        <div className="footer_right_ul">
                            <ul>
                                <li><Link to="#">Privacy Policy</Link></li>
                                <li><Link to="#">Terms & Conditions</Link></li>
                                <li><Link to="#">Cookies</Link></li>
                            </ul>
                        </div>
                    </div>
                </Container>
            </div>
        </footer>
    )
}