import React from 'react';
import './NavItem.css';

import { Link } from 'react-router-dom';
import { rightArrow, services, signupUser, toggleBar } from '../../../../images';
import { DefaultState } from '../../../../context/defaultContext';
import UserDropDown from '../userdropdown/UserDropDown';

const NavItem = ({ history, ...props }) => {

  const openOverlay = () => {
    document.getElementById("_overlay").style.height = "100%";
    document.getElementById("_overlay").style.top = "0";
  }

  const closeOverlay = () => {
    document.getElementById("_overlay").style.height = "0%";
    document.getElementById("_overlay").style.top = "-400px";
  }

  const { isAutorized } = DefaultState()

  // const oncocntactclick = () => {
  //   if (window.location.pathname !== '/') {
  //     history.push('/')
  //     setTimeout(() => {
  //       eventBus.dispatch("scrolltocontact", { message: "Scroll to contact" });
  //     }, 200)
  //   } else {
  //     eventBus.dispatch("scrolltocontact", { message: "Scroll to contact" });
  //   }
  // }

  // const onhowitworksclick = () => {
  //   if (window.location.pathname !== '/') {
  //     history.push('/')
  //     setTimeout(() => {
  //       eventBus.dispatch("scrolltohowitworks", { message: "Scroll to scrolltohowitworks" });
  //     }, 200)
  //   } else {
  //     eventBus.dispatch("scrolltohowitworks", { message: "Scroll to scrolltohowitworks" });
  //   }
  // }


  return (
    // <Navbar.Collapse id="basic-navbar-nav">
    //   <Nav as="ul" className="mr-auto">
    //     <Nav.Item as="li">
    //       <Nav.Link href="/" >Home</Nav.Link>
    //     </Nav.Item>

    //     {/* <ScrollLink 
    //         to="howitwork" 
    //         spy={true} 
    //         smooth={true} 
    //         duration={500} 
    //         className='some-className' 
    //         activeclassName='some-active-className'
    //       >
    //         HOW IT WORKS
    //       </ScrollLink>  */}

    //     <Nav.Item as="li">
    //       <Nav.Link href="javascript:void(0)" onClick={() => onhowitworksclick()} >HOW IT WORKS</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item as="li">
    //       <Nav.Link href="/Tvi-Marketing" >TVI MARKETING ACADEMY</Nav.Link>
    //     </Nav.Item>

    //     <Nav.Item as="li">
    //       <Nav.Link href="/pricing" >Memberships</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item as="li">
    //       <Nav.Link href="/affiliate" >BECOME AN AFFILIATE</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item as="li">
    //       <Nav.Link href="javascript:void(0)" onClick={() => oncocntactclick()} >Contact</Nav.Link>
    //     </Nav.Item>
    //     <Nav.Item as="li">
    //       <Nav.Link href="/faq" >Faqs</Nav.Link>
    //     </Nav.Item>
    //     {/* <Nav.Item as="li">
    //       <Nav.Link href="/signup" >Sign up</Nav.Link>
    //     </Nav.Item> */}
    //     <Nav.Item as="li" className="d-flex align-items-center">
    //       <Nav.Link href="/signup" target='_blank' className="trial_button_nav" >GET A FREE 7-DAY TRIAL</Nav.Link>
    //     </Nav.Item>
    //   </Nav>
    // </Navbar.Collapse>


    <div className="upcoming_events">
      <div className="upcoming_events_menu">
        <ul className="upcoming_events_listing">
          <li className="up_events"><Link to="#">UPCOMING EVENTS <img className='pe-0' alt="rightarrow" src={rightArrow} /></Link>
          </li>
          {
            isAutorized ? (
              <UserDropDown />
            ) : (
              <>
                <li className="login_link up_events"><Link to="/membership"> <img className='ps-0' alt="service" src={services} /> Services</Link></li>
                <li className="sign_link up_events"><Link to="/signin"><img className='ps-0' alt="signupUser" src={signupUser} /> Membership</Link></li>
              </>
            )
          }
        </ul>
        <div className="menu d-flex align-items-center">
          <button id="openMenuBtn" onClick={openOverlay}><img className='pe-2' alt="mav_toggle" src={toggleBar} />
            Menu</button>
        </div>


        <div className="overlay" id="_overlay">
          <div className="d-flex justify-content-end">
            <button className="closebtn" onClick={closeOverlay}>&times;</button>
          </div>
          <div className="overlay-wrapper">
            <div className="overlay-content">
              <ul>
                <li><Link to="#">Home</Link></li>
                <li><Link to="#">About Us</Link>
                  <ul>
                    <li><Link to="#">Our Story</Link></li>
                    <li><Link to="#">Partners</Link></li>
                  </ul>
                </li>
                <li><Link to="/membership">Membership</Link></li>

                <li><Link to="#">Events</Link>
                  <ul>
                    <li><Link to="#">Online</Link></li>
                    <li><Link to="#">Live</Link></li>
                  </ul>
                </li>
                <li>
                  <Link to="#">Business Growth Services</Link>
                  <ul>
                    <li><Link to="#">BLG Growth Packages</Link></li>
                    <li><Link to="#">BLG Event Creation and Promotion</Link></li>
                    <li><Link to="#">BLG Media Packages</Link></li>
                    <li><Link to="#">BLG Funding Solutions</Link></li>
                    <li><Link to="#">BLG Legal</Link></li>
                    <li><Link to="#">BLG Tender Writing</Link></li>
                  </ul>
                </li>
                <li><Link to="#">Business Live Global Media</Link></li>
                <li><Link to="#">Testimonials</Link></li>
                <li><Link to="#">Contact Us</Link></li>
                <li className="upcoming_evnt"><Link to="#">Upcoming Events</Link></li>
                <li className="upcoming_evnt"><Link to="#">Services</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NavItem;













