/* eslint-disable react-hooks/exhaustive-deps */

import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import './signinup.css';
import logo from '../../images/logo.png'

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction } from '../../redux/actions/authAction';
import * as Yup from 'yup'
import axios from '../../config/axios';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import Toaster from '../../helper/Toaster';
import Loader from '../../components/shared/loader/Loader';

export default function SignIn() {

    const dispath = useDispatch()
    const { loading, error, userdetails } = useSelector((state) => state.login)

    const formSchema = Yup.object().shape({
        email: Yup.string().required("Please enter your email id").email("Please provide a valid email").test({
            message: "This email is not registered",
            test: async (value) => {
                let check = await axios.post('/check-email', { email: value })
                return !check.data.valid
            }
        }),
        password: Yup.string().required("Please enter your password").min(6, "Password length should be at least 6 characters").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/, "One Uppercase, One Lowercase, One Number and One Special Case Character")
    });

    const { register, handleSubmit, formState: { errors }, clearErrors, reset } = useForm({ resolver: yupResolver(formSchema), mode: "all" })

    useEffect(() => {
        if (userdetails) {
            clearErrors()
            reset()
        }
        if (error) {
            Toaster(error, 'error')
        }
    }, [userdetails, error])

    const FormSubmit = (data) => {
        dispath(LoginAction(data))
    }

    return (
        <Container>
            {loading && <Loader />}
            <div className='sign_body mt-5 mb-5'>
                <Row className='ms-0 me-0'>
                    <Col md={6} className='signInUp_LeftPane d-flex align-items-center justify-content-center'>
                        <div className='d-flex'>
                            <Col md={12}>
                                <img alt='business-live-global' src={logo} />
                                <h2 className='text-center mt-4 mb-4'>How it works?</h2>
                                <ul>
                                    <li>We will received eco-friendly tips and resources for sustainable living.</li>
                                    <li>We will received eco-friendly tips and resources for sustainable living.</li>
                                    <li>We will received eco-friendly tips and resources for sustainable living.</li>
                                    <li>We will received eco-friendly tips and resources for sustainable living.</li>
                                </ul>

                                <h3> Proceed to Login {"->"} </h3>

                            </Col>
                        </div>

                    </Col>
                    <Col md={6} className='signInUp_RightPane d-flex flex-column align-items-center justify-content-center'>

                        <h3 className='h3_heading  pb-4'>Sign In</h3>

                        <Form className='loginForm text-start' onSubmit={handleSubmit(FormSubmit)}>
                            <Form.Group className="col-md-12 mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label htmlFor="inputPassword5">Email <span>*</span></Form.Label>
                                <Form.Control placeholder="enter your email" title="enter your email" type='email' {...register("email")} />
                                {errors.email && (<p className='text-danger position-absolute text-center'> {errors.email.message} </p>)}
                            </Form.Group>

                            <Form.Group className="col-md-12 mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Label htmlFor="inputPassword5">Password <span>*</span></Form.Label>
                                <Form.Control placeholder="enter your password" title="enter your password " {...register("password")} type='password' />
                                {errors.password && (<p className='text-danger position-absolute text-center'> {errors.password.message} </p>)}
                            </Form.Group>

                            <Col md={12} className='d-flex justify-content-end forgot_pass'><strong><Link to='#'>Forgot Password?</Link></strong></Col>
                            <Button className="bgl_button" type='submit'>Login</Button>
                        </Form>

                        <Col md={12} className='d-flex justify-content-center forgot_pass mt-4'><strong>New to this site? <Link to='/membership'>Sign Up</Link></strong></Col>

                    </Col>
                </Row>
            </div>
        </Container>
    )
}