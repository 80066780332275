import Axios from 'axios'

const baseURL = "https://blgui-devapi.wgtechnologiespvtltd.com"
// const baseURL = "http://localhost:4100"

const axios = Axios.create({ baseURL })

axios.interceptors.request.use(config => {
    if (localStorage.getItem("authtoken")) {
        config.headers.Authorization = `Bearer ${localStorage.getItem("authtoken")}`
    }
    return config
}, error => {
    Promise.reject(error)
})

export default axios