// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader{
    top: 0;
    left: 0;
    position: fixed;
    min-width: 98vw;
    min-height: 80vh;
    background: #000000b0;
    opacity: 0.9;
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-width: 100vw;
    min-height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/loader/Loader.css"],"names":[],"mappings":"AAAA;IACI,MAAM;IACN,OAAO;IACP,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":[".loader{\n    top: 0;\n    left: 0;\n    position: fixed;\n    min-width: 98vw;\n    min-height: 80vh;\n    background: #000000b0;\n    opacity: 0.9;\n    z-index: 999999;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    min-width: 100vw;\n    min-height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
