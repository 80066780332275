import React, { useEffect } from 'react'

import { Container } from 'react-bootstrap';

import './header.css';

import NavItem from './NavItems/NavItem';
import { logo } from '../../../images';
import { Link, useLocation } from 'react-router-dom';

export default function Header() {

    const Location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [Location.pathname])


    return (
        <>
            <header className="header">
                <Container fluid>
                    <div className="inner_header">
                        <div className="header_top">
                            <div className="logo">
                                <Link to={"/"}>
                                    <img alt="business-live-global-logo" src={logo} />
                                </Link>
                            </div>
                            <NavItem />
                        </div>
                    </div>
                </Container>
            </header>

            {/* <Navbar bg="light" expand="lg" fixed="top" variant="dark">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />


                    <Navitem />
                </Container>
            </Navbar> */}
        </>
    )
}