import React from 'react'
import Header from '../../components/header/auth-header/header'
import { Outlet } from 'react-router-dom'
import Footer from '../../components/footer/footer'

export default function AuthLayout() {
    return (
        <>
            <Header />
            <div>
                <Outlet />
            </div>
            <Footer />
        </>
    )
}